<!--
--------------------------------------------------------------------------------
<copyright file="LaserAvailability.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.time_scale_h') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.evaluated_machine_status') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="work"> {{ $t('widget_info_text.work') }}</span> :
          {{ $t('widget_info_text.cut_availab_work_txt') }}
        </li>
        <li class="list-item">
          <span class="error"> {{ $t('widget_info_text.error') }}</span> :
          {{ $t('widget_info_text.cut_availab_error_txt') }}
        </li>
        <li class="list-item">
          <span class="wait"> {{ $t('widget_info_text.wait') }}</span
          >:
          {{ $t('widget_info_text.cut_availab_wait_txt') }}
          <p class="text">- {{ $t('widget_info_text.cut_availab_wait_txt1') }}</p>
          <p class="text">- {{ $t('widget_info_text.cut_availab_wait_txt2') }}</p>
        </li>
        <li class="list-item">
          <span class="idle"> {{ $t('widget_info_text.idle') }}</span> :
          {{ $t('widget_info_text.cut_availab_idle_txt') }}
          <p class="text">{{ $t('widget_info_text.cut_availab_idle_txt1') }}</p>
        </li>
        <li class="list-item">
          <span class="stop"> {{ $t('widget_info_text.stop') }}</span> :
          {{ $t('widget_info_text.cut_availab_stop_txt') }}
        </li>
        <li class="list-item">
          <span class="offline"> {{ $t('widget_info_text.offline') }}</span> :
          <p class="text">{{ $t('widget_info_text.cut_availab_offline_txt1') }}</p>
          <p class="text">{{ $t('widget_info_text.cut_availab_offline_txt2') }}</p>
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.bars_txt_maq_stat') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold" style="text-transform: capitalize">
            {{ $t('widget_info_text.top_bar') }}</span
          >
          – <span class="blue-bar"> {{ $t('widget_info_text.blue') }} </span> :
          {{ $t('widget_info_text.selected_period') }}
        </li>
        <li class="list-item">
          <span class="demi-bold" style="text-transform: capitalize">
            {{ $t('widget_info_text.bottom_bar') }}</span
          >
          - <span class="green-bar"> {{ $t('widget_info_text.green') }}</span> :
          {{ $t('widget_info_text.previous_period') }}
        </li>
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.bar_length') }} </span> :
          {{ $t('widget_info_text.bar_len_txt') }}
        </li>
      </ul>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.bars_info_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_cat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-filter />
      <shift-filter />
      <expand-btn />
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';
import Expand from '@/components/Charts/widgetInfo/Expand.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
    'expand-btn': Expand,
  },
})
export default class LaserAvailabilityWidgetInfo extends Vue {}
</script>
